<template>
  <div>
    <div class="mb-3">
      <Breadcrumbs :items="items" />
    </div>
    <tablaComponent
      ref="tabla"
      :headers="headers"
      :titulo-tabla="tituloTabla"
      :url="url"
      :cargando="cargando"
      :search="search"
      :titulo-search="tituloSearch"
      :have-actions="true"
      :have-detail="haveDetail"
      @detailitem="detailItem($event)"
      @dialogSearch="cargarSearch()"
      @refreshSearch="refreshSearch()"
    >
      <template v-slot:filterSearch>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="filterFilesSelected"
              :items="filterFiles"
              item-value="id"
              item-text="name"
              outlined
              dense
              chips
              small-chips
              label="Filtrar por"
              multiple
              hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                  class="elevation-2 my-1"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeFilterFilesSelected(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- UserName -->
          <v-col
            v-if="isUserNameSelected"
            cols="12"
          >
            <v-text-field
              v-model="UserNameSearch"
              label="Usuario"
              outlined
              dense
              placeholder="Buscar por usuario"
              hide-details
            ></v-text-field>
          </v-col>
          <!-- ObjectId -->
          <v-col
            v-if="isObjectIdSelected"
            cols="12"
            md="6"
          >
            <v-select
              v-model="ObjectIdSearch"
              :items="listaObras"
              item-value="id"
              item-text="name"
              label="Obras"
              dense
              outlined
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
    </tablaComponent>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiArrowLeft, mdiContentCopy,
  mdiArrowRight, mdiCalendar,
} from '@mdi/js'
import { ref, reactive, computed } from '@vue/composition-api'
import moment from 'moment'
import HTTP from '@/utils/axios/axios-config-base'
import { useRouter } from '@/utils'
import tablaComponent from '@/components/tablaComponent.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: { Breadcrumbs, tablaComponent },
  created() {
    if (this.$route.params.notificaciones != null) {
      this.notificaciones.snackbar = this.$route.params.notificaciones.snackbar
      this.notificaciones.text = this.$route.params.notificaciones.text
      this.notificaciones.color = this.$route.params.notificaciones.color
    }
  },
  setup() {
    const { router } = useRouter()
    const tituloTabla = ref('Log del Sistema')
    const url = ref(`${HTTP.defaults.baseURL}identity-api/SystemLogs`)
    const cargando = ref(false)
    const haveDetail = ref(true)
    const headers = ref([
      {
        text: 'Nombre', sortable: true, value: 'name', width: '200',
      },
      { text: 'Usuario', sortable: true, value: 'userName' },
      { text: 'Tipo de Operación', sortable: true, value: 'operationType' },
      { text: 'Fecha', sortable: true, value: 'createdAt' },
      {
        text: 'Acción', value: 'actions', align: 'center', width: '180', sortable: false,
      },
    ])
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const tabla = ref(null)
    const search = ref({})
    const tituloSearch = ref('Buscar Log del Sistema')
    const filterFiles = [
      { id: 'UserName', name: 'Usuario' },
      { id: 'ObjectId', name: 'Obra' },
    ]
    const filterFilesSelected = ref([])
    const isUserNameSelected = computed(() => filterFilesSelected.value.includes('UserName'))
    const UserNameSearch = ref('')
    const isObjectIdSelected = computed(() => filterFilesSelected.value.includes('ObjectId'))
    const ObjectIdSearch = ref('')
    const listaObras = reactive([])
    const getObras = async () => {
      const URL = `${HTTP.defaults.baseURL}/publicworks-api/publicWork`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        for (let index = 0; index < resp.data.value.entities.length; index += 1) {
          listaObras.push(resp.data.value.entities[index])
        }
      }
    }
    getObras()

    const detailItem = item => {
      router.push({ name: 'details_log_sistema', params: { id: item.systemLogId } })
    }

    const permissions = localStorage.getItem('permissions')

    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: `${tituloTabla.value}`,
        disabled: true,
        href: '/log-sistema',
      },
    ])

    return {
      headers,
      router,
      tituloTabla,
      url,

      // loadin
      cargando,
      tabla,
      search,
      tituloSearch,
      filterFiles,
      filterFilesSelected,
      isUserNameSelected,
      UserNameSearch,
      isObjectIdSelected,
      ObjectIdSearch,
      listaObras,
      items,
      haveDetail,

      // icons
      icons: {
        mdiPencil,
        mdiDelete,
        mdiArrowLeft,
        mdiArrowRight,
        mdiContentCopy,
        mdiCalendar,
      },
      notificaciones,
      getObras,
      detailItem,
      cargarSearch: () => {
        const searchAll = {}
        if (filterFilesSelected.value.includes('UserName')) {
          if (UserNameSearch.value !== '') {
            searchAll.UserName = UserNameSearch.value
          }
        }
        if (filterFilesSelected.value.includes('ObjectId')) {
          if (ObjectIdSearch.value !== '') {
            searchAll.ObjectId = ObjectIdSearch.value
          }
        }
        search.value = searchAll
      },
      refreshSearch: () => {
        filterFilesSelected.value = []
        search.value = {}
      },
      removeFilterFilesSelected: async item => {
        const index = filterFilesSelected.value.indexOf(item.id)
        if (index >= 0) filterFilesSelected.value.splice(index, 1)
      },
    }
  },
}
</script>

<style>
  .truncate_title {
      max-width: 20vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .truncate {
      max-width: 35vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
</style>
